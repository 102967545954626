import _2019 from './2019';
import _2020 from './2020';
import _2021 from './2021';
import _2022 from './2022';
import _2023 from './2023';
import _2024 from './2024';

export const year2019 = _2019;
export const year2020 = _2020;
export const year2021 = _2021;
export const year2022 = _2022;
export const year2023 = _2023;
export const year2024 = _2024;